import React from 'react';
import styled, { css } from 'styled-components';

import CarImg from '../../assets/car.png';

export const HeaderContainer = styled.header`
  background-color: #f4f7ef;
  height: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '19.5rem' : '13.75rem')};
  padding: 2rem 0 0 0;
  text-align: center;
`;

export const StepIndicator = styled.span`
  color: #007ab3;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`;

export const StepIndicatorRounded = styled.div`
  background-color: #fff;
  border: 0.125rem solid #007ab3;
  border-radius: 50%;
  height: 1rem;
  position: relative;
  top: 0.125rem;
  width: 1rem;

  &:first-child {
    margin: 0;
  }

  &::after {
    content: '${({ content }) => content}';
    position: absolute;
    top: 1.25rem;
  }
`;

export const MainTitle = styled.h1`
  margin: 1.875rem 0 0;
  text-transform: none;
`;

export const Image = styled(({ className }) => <img className={className} src={CarImg} alt="car" />)`
  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          margin: 4rem 0 0;
          width: 16rem;
        `
      : css`
          margin: 2rem 0 0;
          width: 10rem;
        `}
`;
