const baseValidationApiUrl = 'https://lmt.timeonegroup.com/api/validation/';

async function validateWithApi(url, value) {
  const errorMessage = 'Merci de saisir une valeur valide.';
  const valueEncode = encodeURIComponent(value);
  const response = await fetch(`${url}?string=${valueEncode}`);
  const { result } = await response.json();

  return result === true ? undefined : errorMessage;
}

export const validateName = value => validateWithApi(`${baseValidationApiUrl}name`, value);

export const validateEmail = value => validateWithApi(`${baseValidationApiUrl}email`, value);

export const validatePhoneNumber = value => validateWithApi(`${baseValidationApiUrl}phonenumber`, value);

export const validateZipCode = value => validateWithApi(`${baseValidationApiUrl}zipcode`, value);

function testValueWithRegex(value, regex, errorMessage) {
  if (regex.test(value)) {
    return undefined;
  }

  return errorMessage;
}

export function validateOnlyAlphaNum(value) {
  const regex = /^[a-zA-ZÀ-ÿ0-9\s-]+$/;
  const errorMessage = 'Uniquement des charactères alphanumériques.';

  return testValueWithRegex(value, regex, errorMessage);
}
