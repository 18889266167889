import React from 'react';
import { InputSelect } from 'timeone-components';
import styled from 'styled-components';
import { Field, FormSpy } from 'react-final-form';

import AppContext from '../App/App.context';

export const Label = styled.label`
  appearance: none;
  display: inline-block;
  margin: 0 0 2.5rem;
  position: relative;
  text-align: left;
  width: 100%;

  &.number {
    height: 3.125rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  input {
    width: 100%;
  }

  .select__control,
  .select__control:hover {
    border-bottom: 1px solid #303747;
    padding-top: 0.75rem;

    .select__value-container {
      padding: 0.125rem;

      .select__single-value {
        text-transform: none;
      }
    }
  }

  .select__option {
    text-transform: none;
  }

  &::after {
    background: #303747;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 25%;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  &.active::after {
    left: 0;
    width: 100%;
  }
`;

export const LabelSpan = styled.span`
  font-weight: bold;
  left: 0.25rem;
  position: absolute;
  text-transform: none;
  top: 1.25rem;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.18, 1.04);

  &.active {
    font-size: 0.875rem;
    top: 0;
  }

  &:after {
    color: #eb0045;
    content: '${({ required }) => (required ? '*' : '')}';
    padding-left: 0.25rem;
  }
`;

export const LabelError = styled.span`
  color: #eb0045;
  font-size: 0.875rem;
  left: 0.25rem;
  position: absolute;
  top: 3.5rem;
  transition: all 0.25s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
`;

function renderInput(Input) {
  function requiredTest(value) {
    const isRequiredMessage = 'Le champ doit contenir une valeur.';

    let testResult = null;

    if (Input === InputSelect) {
      testResult =
        !(
          !!value &&
          !Array.isArray(value) &&
          Object.keys(value).length === 2 &&
          Object.prototype.hasOwnProperty.call(value, 'label') &&
          Object.prototype.hasOwnProperty.call(value, 'value')
        ) && isRequiredMessage;
    } else {
      testResult = !(!!value && value.length > 0) && isRequiredMessage;
    }

    return testResult;
  }

  class CustomInput extends Input {
    handleValidate = (value, meta, formProps) => {
      const { setIsFormLocked } = this.context;
      const { validation, required } = this.props;
      const { invalid, errors } = formProps;

      if (meta.error || invalid || Object.entries(errors).length !== 0) {
        setIsFormLocked(true);
      }

      return new Promise(resolve => {
        if (this.clearTimeout) {
          this.clearTimeout();
        }

        const requiredTestResult = requiredTest(value);

        if (required && requiredTestResult) {
          resolve(requiredTestResult || undefined);
        }

        const timerId = setTimeout(async () => {
          if (validation && value) {
            const result = await validation(value);

            if (!result) {
              setIsFormLocked(false);
            }

            resolve(result);
          } else {
            resolve();
          }
        }, 300);

        this.clearTimeout = () => {
          clearTimeout(timerId);
          resolve(undefined);
        };
      });
    };

    render() {
      const { change, className, config, name, label, required } = this.props;

      let prevValue;

      return (
        <FormSpy>
          {formProps => (
            <Field
              name={name}
              subscription={{
                touched: true,
                active: true,
                data: true,
                initial: true,
                error: true,
              }}
            >
              {({ meta }) => (
                <Field
                  name={name}
                  validate={value => (value !== prevValue ? this.handleValidate(value, meta, formProps) : meta.error)}
                >
                  {({ input }) => {
                    prevValue = input.value;

                    return (
                      <Label
                        className={`${className}${this.handleIsActive(input, meta) ? ' active' : ''}`}
                        htmlFor={name}
                      >
                        <LabelSpan required={required} className={this.handleIsActive(input, meta) ? 'active' : null}>
                          {label}
                        </LabelSpan>
                        {change && change(input.onChange)}
                        {this.inputRender({ ...input, ...config }, meta)}
                        <LabelError className={this.handleIsInValide(meta) ? 'visible' : 'hidden'}>
                          {meta.error}
                        </LabelError>
                      </Label>
                    );
                  }}
                </Field>
              )}
            </Field>
          )}
        </FormSpy>
      );
    }
  }

  CustomInput.contextType = AppContext;

  return CustomInput;
}

export default renderInput;
