import React from 'react';

import { validateName } from '../utils/validations';
import Input from './Input';

const NameInputs = () => (
  <>
    <Input name="firstName" label="Prénom" validation={validateName} required />
    <Input name="lastName" label="Nom" validation={validateName} required />
  </>
);

export default NameInputs;
