import React from 'react';

import AppContext from '../../App/App.context';
import productTypes from '../../data/vehicleBrands';
import { validateOnlyAlphaNum } from '../../utils/validations';
import formatSelectOptions from '../../utils/formatSelectOptions';
import Input from '../Input';
import NameInputs from '../NameInputs';
import LocationInputs from '../LocationInputs';
import Select from '../Select';

const productTypeOptions = formatSelectOptions(productTypes);

function ProductInformations() {
  return (
    <AppContext.Consumer>
      {({ currentMediaConfig }) => (
        <>
          <Select name="vehicleBrand" label="Marque du véhicule" options={productTypeOptions} isSearchable required />
          <Input name="modele" label="Modèle" validation={validateOnlyAlphaNum} required />
          {currentMediaConfig === 'large' && <NameInputs />}
          <LocationInputs />
        </>
      )}
    </AppContext.Consumer>
  );
}

export default ProductInformations;
