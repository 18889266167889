export default [
  'ALEKO',
  'ALFA ROMEO',
  'ALPINE RENAULT',
  'ARO',
  'ASIA',
  'ASTON MARTIN',
  'AUDI',
  'AUSTIN',
  'AUTOBIANCHI',
  'AUVERLAND',
  'BEDFORD',
  'BEE BEE AUTOMOTIV',
  'BERTONE',
  'BMW',
  'BUICK',
  'CADILLAC',
  'CHEVROLET',
  'CHRYSLER',
  'CITROEN',
  'COURB',
  'DACIA',
  'DAEWOO',
  'DAF',
  'DAIHATSU',
  'DAIMLER',
  'DATSUN',
  'DODGE',
  'DS',
  'E BENTLEY',
  'EBRO',
  'FIAT',
  'FISKER',
  'FORD',
  'FSO-POLSKI',
  'GAC GONOW',
  'GME',
  'GRANDIN',
  'HONDA',
  'HYUNDAI',
  'INFINITI',
  'INNOCENTI',
  'ISUZU',
  'IVECO',
  'JAGUAR',
  'JEEP',
  'KIA',
  'LADA',
  'LANCIA',
  'LAND ROVER',
  'LDV',
  'LEXUS',
  'LOTUS',
  'MAHINDRA',
  'MAN',
  'MARUTI',
  'MASERATI',
  'MATRA',
  'MAZDA',
  'MCC',
  'MEGA',
  'MERCEDES',
  'MG',
  'MIA',
  'MINI',
  'MITSUBISHI',
  'MPM MOTORS',
  'NISSAN',
  'OPEL',
  'PANHARD',
  'PEUGEOT',
  'PIAGGIO',
  'PONTIAC',
  'PORSCHE',
  'PROTON',
  'RENAULT',
  'ROVER',
  'SAAB',
  'SANTANA',
  'SEAT',
  'SKODA',
  'SMART',
  'SSANGYONG',
  'SUBARU',
  'SUNBEAM',
  'SUZUKI',
  'TALBOT',
  'TATA',
  'TESLA',
  'THINK',
  'TOYOTA',
  'TRIUMPH',
  'UMM',
  'VOLKSWAGEN',
  'VOLVO',
  'ZASTAVA',
  'ZAZ',
  'Autre',
];
