import React from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { HeaderContainer, Image, MainTitle, StepIndicator } from './styledComponents';
import ProgressBar from './ProgressBar';

const Header = ({ page, stepsName }) => (
  <AppContext.Consumer>
    {({ currentMediaConfig }) => (
      <HeaderContainer>
        {currentMediaConfig === 'large' ? (
          <ProgressBar percentage={page === 0 ? 0 : (stepsName.length / (page + 1)) * 100} steps={stepsName} />
        ) : (
          <div>
            <StepIndicator>
              {page + 1}/{stepsName.length}
            </StepIndicator>
            <span>{stepsName[page]}</span>
          </div>
        )}
        <MainTitle>Assurer mon véhicule</MainTitle>
        <Image />
      </HeaderContainer>
    )}
  </AppContext.Consumer>
);

Header.propTypes = {
  page: PropTypes.number.isRequired,
  stepsName: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
