import React from 'react';

import { ContactNoticeContainer, Notice, Title, Link } from './styledComponents';

const ContactNotice = () => (
  <ContactNoticeContainer>
    <Title>Coordonnées de contact</Title>
    <Notice>
      Nous collectons vos données personnelles uniquement pour vous recontacter dans le cadre de votre projet
      d’assurance automobile.
    </Notice>
    <Notice>
      Pour en savoir plus sur la protection de vos données{' '}
      <Link
        href="https://www.allianz.fr/information/protection-donnees-personnelles-cnil-retarg/"
        target="_blank"
        rel="noopener noreferrer"
      >
        cliquez ici
      </Link>
      .
    </Notice>
  </ContactNoticeContainer>
);

export default ContactNotice;
