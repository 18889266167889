import React from 'react';

import Confirmation from '../Confirmation';
import ContactInformations from '../ContactInformations';
import InputRequiredNotice from '../InputRequiredNotice';
import ProductInformations from '../ProductInformations';
import { LoadTagCoWithComponent } from '../../utils/loadTagCo';

export default function getSteps({ onValidate, onSubmit }) {
  const confirmationStep = { name: 'Confirmation', render: () => <Confirmation /> };

  return {
    small: [
      {
        name: 'Votre véhicule',
        onValidate,
        render: () => (
          <>
            <ProductInformations />
            <InputRequiredNotice />
          </>
        ),
      },
      {
        name: 'Coordonnées',
        onValidate: onSubmit,
        render: () => (
          <LoadTagCoWithComponent pageName="form2" tagId="104">
            <ContactInformations />
            <InputRequiredNotice />
          </LoadTagCoWithComponent>
        ),
      },
      confirmationStep,
    ],
    large: [
      {
        name: 'Votre véhicule',
        onValidate: onSubmit,
        render: () => (
          <>
            <ProductInformations />
            <ContactInformations />
            <InputRequiredNotice />
          </>
        ),
      },
      confirmationStep,
    ],
  };
}
