import loadTagCo from '../utils/loadTagCo';

export default values =>
  fetch('/api?case=form', {
    method: 'POST',
    body: JSON.stringify({ ...values, ...window.contextTagCo }),
  }).then(response => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then(json => {
        window.tc_vars = {
          page_name: 'confirmation',
          conversion_file_id: json.id_lead,
        };
        loadTagCo('104');
      });
    }
    return false;
  });
